/*
 * Возвращает JSON безопасненько из строки
 * @param prop (String) - строка, из которой будет делать JSON
 */
function getJSON(prop, arrayAllowed = true) {
  const isJSONString = function (str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  if (typeof prop == "string" && isJSONString(prop)) prop = JSON.parse(prop);

  if (!arrayAllowed && Array.isArray(prop)) return {};

  if (!prop) return {};

  return prop;
}

export default getJSON;
