<template>
  <div class="regular-page regular-page--white">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>
            <router-link to="/albums">Фотографии</router-link>
          </li>
          <li>Загрузка фото</li>
        </ul>
        <h1 class="regular-page__title">Загрузка фото</h1>
      </div>
    </div>
    <form class="container" @submit.prevent="validate(true)">
      <div class="upload-photo">
        <Dropzone />
        <div class="upload-photo__list">
          <div
            class="upload-photo__item"
            v-for="(file, index) in files"
            :key="index"
          >
            <div class="upload-photo__preview">
              <img :src="file.url" v-if="file.url" />
              <SvgIcon :name="'noimage'" v-else />
            </div>
            <div class="upload-photo__form">
              <template v-if="file.hasOwnProperty('model')">
                <textarea
                  class="field__input"
                  v-model="filesCaptions[file.model.id]"
                ></textarea>
                <div class="upload-photo__actions">
                  <a href="#" @click.prevent="onFileDelete(index)">Удалить</a>
                </div>
              </template>
              <div
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                class="progress-bar"
                v-else
              >
                <div class="progress-bar__bar"></div>
                <div class="progress-bar__bar progress-bar__bar--short"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="upload-photo__form form form--full">
          <div class="form__wrapper">
            <div class="field">
              <input
                placeholder="Ваше имя"
                type="text"
                class="field__input"
                v-model="formData.name"
                :class="{ error: errors.name }"
              />
              <div class="field__error">{{ errors.name }}</div>
            </div>
            <div class="field">
              <input
                placeholder="Ссылка на аккаунт в соц. сети или емейл"
                type="text"
                class="field__input"
                v-model="formData.link"
              />
            </div>
            <div class="field">
              <label
                ><input type="checkbox" v-model="formData.confirmation" />Я
                принимаю условия
                <a href="/privacy-policy" target="_blank"
                  >Политики конфиденциальности</a
                >
                и
                <a href="/terms-of-use" target="_blank"
                  >Пользовательского соглашения</a
                ></label
              >
              <div class="field__error">{{ errors.confirmation }}</div>
            </div>
          </div>
        </div>
        <div class="upload-photo__action buttons-wrapper">
          <router-link class="button button--white" to="/albums"
            >Отмена</router-link
          >
          <button type="submit" class="button">Сохранить</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Dropzone from "@/components/Dropzone.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import AjaxProvider from "@/utils/AjaxProvider.js";
import Validator from "@/utils/Validator.js";
import getJSON from "@/utils/getJSON.js";
import swal from "sweetalert";

const name = "UploadPhoto";

function data() {
  return {
    formData: {
      name: "",
      link: "",
    },
    validationRules: {
      name: "required",
      confirmation: "confirm",
    },
    errors: {
      name: "",
      confirmation: "",
    },
    validationRequest: false,
    filesCaptions: {},
  };
}

const computed = {
  counter() {
    return this.$store.state.upload.counter;
  },
  files() {
    this.counter;
    return this.$store.state.upload.files;
  },
  sessionKey() {
    return this.$store.state.upload.sessionKey;
  },
  loading() {
    return this.$store.state.common.loading;
  },
};

const watch = {
  validationRequest(value) {
    let inst = this;
    if (value) {
      inst.$watch(
        "formData",
        () => {
          inst.validate();
        },
        {
          deep: true,
        }
      );
    }
  },
};

function validate(enableSubmit = false) {
  const inst = this;
  const validation = new Validator(inst.formData, inst.validationRules).make();
  inst.errors = validation.errors;

  if (!validation.isValid) {
    inst.validationRequest = true;
    return;
  }

  enableSubmit && inst.submit();
}

function submit() {
  const inst = this;

  if (inst.loading) {
    return;
  }

  inst.$store.commit("common/setRequest", true);

  const captions = {};

  Object.keys(inst.filesCaptions).forEach((file_id) => {
    let obj = {
      description: inst.filesCaptions[file_id],
    };
    captions[file_id] = obj;
  });
  console.log(captions);
  const author = inst.formData.name;
  const contact = inst.formData.link;

  const data = {
    captions,
    contact,
    author,
    _session_key: inst.sessionKey,
  };

  new AjaxProvider("albums", "post", data).request((xhr) => {
    if (xhr.status == 200) {
      inst.$store.commit("upload/reset");
      swal(
        "Спасибо!",
        "Альбом успешно загружен и появится на сайте после проверки модераторами!",
        "success"
      );
    } else {
      let response = getJSON(xhr.responseText);
      let message = response.error || "Что-то пошло не так";
      swal("Ошибка", message, "error");
    }
    inst.$store.commit("common/setRequest", false);
  });
}

function onFileDelete(fileIndex) {
  const inst = this;

  inst.$store.commit("common/setRequest", true);
  new AjaxProvider("albums/upload/delete", "post", {
    _session_key: inst.sessionKey,
    file_id: inst.files[fileIndex].model.id,
  }).request((xhr) => {
    if (xhr.status == 200) {
      inst.$store.commit("upload/removeByIndex", fileIndex);
    } else {
      let response = getJSON(xhr.responseText);
      let message = response.error || "Что-то пошло не так";
      swal("Ошибка", message, "error");
    }
    inst.$store.commit("common/setRequest", false);
  });
}

export default {
  name,
  data,
  computed,
  watch,
  methods: {
    validate,
    submit,
    onFileDelete,
  },
  components: {
    Dropzone,
    SvgIcon,
  },
};
</script>
