<template>
  <div class="regular-page regular-page--dark">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>
            <router-link to="/videos">Видео</router-link>
          </li>
        </ul>
        <div class="regular-page__description" v-if="fetched">
          <h1 class="regular-page__description-title">
            Видеоцикл «{{ cycle.title }}»
          </h1>
        </div>
      </div>
    </div>
    <div class="video-gallery">
      <section class="video-gallery__section" v-if="fetched">
        <h3 class="video-gallery__title"></h3>
        <div class="container">
          <div class="row">
            <div
              class="col-s-6 col-x-12"
              v-for="video in cycle.videos"
              :key="video.id"
            >
              <VideoItem :video="video" @click="openVideo(video.link)" />
            </div>
          </div>
        </div>
      </section>
      <div v-else class="loader-wrap loader-wrap--static">
        <div class="circle-loader"></div>
      </div>
    </div>
  </div>
</template>
<script>
import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";

import VideoItem from "@/components/VideoItem.vue";
const name = "CycleItem";

function data() {
  return {
    fetched: false,
    cycle: {},
  };
}

function created() {
  this.fetchData();
}

function fetchData() {
  const inst = this;

  new AjaxProvider(`cycles/${inst.$route.params.id}`, "get").request(
    (xhr) => {
      const response = getJSON(xhr.responseText);
      if (xhr.status == 200) {
        inst.cycle = response;
        document.title = response.title;
      }
      inst.fetched = true;
    },
    (response) => {
      inst.fetched = true;
      inst.handleAPIError(response);
    }
  );
}

function openVideo(link) {
  this.$store.commit("cycles/setOpenedVideo", link);
}

export default {
  name,
  data,
  created,
  methods: {
    fetchData,
    openVideo,
  },
  components: {
    VideoItem,
  },
};
</script>
