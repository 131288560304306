<template>
  <div class="rel">
    <div class="map-history__top">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>Карта</li>
        </ul>
      </div>
    </div>
    <BalloonMap />
  </div>
</template>

<script>
const name = "MapPage";

import BalloonMap from "@/components/map";

export default {
  name,
  components: {
    BalloonMap,
  },
};
</script>
