<template>
  <div>
    <div class="dualphoto" v-if="loadedCount >= 2">
      <div class="dualphoto__container" ref="container">
        <img :src="pic.photo_before.path" ref="image" />
        <div class="dualphoto__upside" :style="upsideContainerStyle">
          <img :src="pic.photo_after.path" :style="upsideImageStyle" />
        </div>
        <div
          class="dualphoto__controller"
          :style="controllerStyle"
          @mousedown="onMouseDown"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
          @touchcancel="onTouchEnd"
        >
          <div class="dualphoto__lines"></div>
          <div class="dualphoto__circle"></div>
        </div>
      </div>
      <div class="dualphoto__description">{{ pic.name }}</div>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
import ResizeObserver from "resize-observer-polyfill";
import Preloader from "@/components/Preloader.vue";

const name = "DualPhoto";

function data() {
  return {
    loadedCount: 0,
    upsideImageGeom: {
      width: 0,
      height: 0,
    },
    position: 50,
    touchDetecting: false,
    touchData: {},
    touchMoving: false,
    downX: 0,
  };
}

const computed = {
  upsideImageStyle() {
    return {
      width: this.upsideImageGeom.width + "px",
      height: this.upsideImageGeom.height + "px",
    };
  },
  controllerStyle() {
    return {
      left: `calc(${this.position}% - 20px)`,
    };
  },
  upsideContainerStyle() {
    return {
      width: `${this.position}%`,
    };
  },
};

const props = {
  pic: {
    type: Object,
    default: () => {
      return {};
    },
  },
};

function created() {
  this.loadData();
}

function loadData() {
  const inst = this;

  if (!inst.pic.photo_before || !inst.pic.photo_after) {
    return;
  }

  const paths = [];
  paths.push(inst.pic.photo_before.path);
  paths.push(inst.pic.photo_after.path);

  paths.forEach((path) => {
    let img = new Image();
    img.onload = function () {
      inst.loadedCount++;
      if (inst.loadedCount >= 2) {
        inst.initObserver();
      }
    };
    img.src = path;
  });
}

function initObserver() {
  const inst = this;
  this.resizeObserver = new ResizeObserver((entries) => {
    const latest = entries.pop();
    const { width, height } = latest.contentRect;
    Object.assign(this.upsideImageGeom, {
      width,
      height,
    });
  });
  inst.$nextTick(() => {
    this.resizeObserver.observe(this.$refs.image);
  });
}

function getMouseEventPosition($event) {
  const inst = this;

  const { left } = inst.$refs.container.getBoundingClientRect();
  const x = $event.clientX - left;
  return x;
}

function onMouseDown($event) {
  const inst = this;

  // get mouse position
  inst.downX = inst.getMouseEventPosition($event);
  document.addEventListener("mousemove", inst.onMouseMove);
  document.addEventListener("mouseup", inst.onMouseUp);
}

function onMouseMove($event) {
  const inst = this;
  const newX = inst.getMouseEventPosition($event);
  const movementX = newX - inst.downX;
  inst.downX = newX;

  inst.movePosition(movementX);
}

function movePosition(movementX) {
  const inst = this;

  const percent = (movementX / inst.upsideImageGeom.width) * 100;
  let value = inst.position + percent;

  if (value < 0) {
    value = 0;
  }

  if (value > 100) {
    value = 100;
  }

  inst.position = value;
}

function onMouseUp($event) {
  const inst = this;

  const movementX = inst.getMouseEventPosition($event) - inst.downX;
  inst.movePosition(movementX);

  inst.downX = 0;
  document.removeEventListener("mousemove", inst.onMouseMove);
  document.removeEventListener("mouseup", inst.onMouseUp);
}

function onTouchStart($event) {
  const inst = this;

  if ($event.touches.length == 1) {
    inst.touchDetecting = true;

    inst.touchData.touch = $event.changedTouches[0];

    inst.touchData.x = inst.touchData.touch.pageX;
    inst.touchData.x_origin = inst.touchData.x;
    inst.touchData.y = inst.touchData.touch.pageY;
  }
}

function onTouchMove($event) {
  const inst = this;
  if (
    inst.touchDetecting &&
    $event.changedTouches[0].identifier == inst.touchData.touch.identifier
  ) {
    const newX = event.changedTouches[0].pageX;
    const newY = event.changedTouches[0].pageY;

    const movementX = newX - inst.touchData.x;

    if (!inst.touchMoving) {
      if (Math.abs(movementX) >= Math.abs(inst.touchData.y - newY)) {
        $event.preventDefault();
        inst.touchMoving = true;
      } else {
        inst.touchDetecting = false;
      }
    }

    if (inst.touchMoving) {
      inst.touchData.x = newX;
      inst.movePosition(movementX);
    }
  } else {
    inst.touchDetecting = false;
  }
}

function onTouchEnd($event) {
  const inst = this;

  if (
    inst.touchDetecting &&
    $event.changedTouches[0].identifier == inst.touchData.touch.identifier &&
    inst.touchMoving
  ) {
    event.preventDefault();

    inst.touchData.newX = event.changedTouches[0].pageX;
    inst.touchData.newY = event.changedTouches[0].pageY;

    const movementX = inst.touchData.newX - inst.touchData.x;
    inst.movePosition(movementX);

    inst.touchDetecting = false;
    inst.touchMoving = false;
    inst.touchData = {};
  }
}

export default {
  name,
  props,
  computed,
  created,
  data,
  methods: {
    loadData,
    initObserver,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    movePosition,
    getMouseEventPosition,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  },
  components: {
    Preloader,
  },
};
</script>
<style lang="scss">
.dualphoto {
  margin-bottom: 60px;
  user-select: none;
}

.dualphoto__container {
  position: relative;

  & > img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }
}

.dualphoto__upside {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1;

  img {
    height: auto;
    display: block;
  }
}

.dualphoto__controller {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  left: calc(50% - 20px);
  width: 40px;
  cursor: ew-resize;
}

.dualphoto__lines {
  position: absolute;
  height: 100%;
  width: 3px;
  left: 18.5px;

  &:before,
  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    background: #fff;
    pointer-events: none;
    height: calc(50% - 20px);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

.dualphoto__circle {
  width: 40px;
  height: 40px;
  border: 3px solid #fff;
  background: tranparent;
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    display: block;
    width: 0;
    height: 0;
    content: "";
  }

  &:before {
    border: 5px solid transparent;
    border-right: 5px solid #fff;
    margin-right: 8px;
  }

  &:after {
    border: 5px solid transparent;
    border-left: 5px solid #fff;
  }
}

.dualphoto__description {
  margin-top: 15px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  color: rgba(0, 0, 0, 0.87);
}
</style>
