<template>
  <div class="regular-page">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>Политика конфиденциальности</li>
        </ul>
        <h1 class="regular-page__title">Политика конфиденциальности</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacyPolicy",
};
</script>
