<template>
  <div class="map-info">
    <gmap-map
      :center="center"
      :zoom="14"
      :options="options"
      mapTypeId="terrain"
      ref="mapRef"
      v-if="fetched"
    >
      <gmap-cluster
        imagePath="/themes/akropotkin/resources/statassets/map/map-cluster"
        :zoomOnClick="true"
        @click="clusterAction"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          class="ffff"
          :draggable="false"
          :icon="icon"
          :infoWindow="'dsdasd'"
          @click="markerAction(m, index)"
        ></gmap-marker>
      </gmap-cluster>
      <gmap-info-window
        :options="infoOptions"
        :position="infoPosition"
        :opened="infoOpened"
        :content="infoContent"
        @closeclick="infoOpened = false"
      />
    </gmap-map>
  </div>
</template>
<script>
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

import mapStyle from "@/utils/mapStyle.js";
import mapStylesPreset from "@/utils/mapStylesPreset.js";

import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";

import Vue from "vue";

Vue.component("GmapCluster", GmapCluster);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBdb_ci_KLSbmrIwJFvF4_TPsB6lUBPlYw",
    libraries: "places",
  },
});

const name = "BalloonsMap";

function data() {
  return {
    fetched: false,
    streets: [],
    icon: {
      path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0",
      fillColor: "#ffb800",
      strokeWeight: 0,
      scale: 1,
      fillOpacity: 1,
    },
    clusterIcon: {
      path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
      fillColor: "#ffb800",
      strokeWeight: 0,
      scale: 1,
      fillOpacity: 1,
    },
    map: null,
    infoWin: "",
    center: { lat: 54.706901, lng: 20.507938 },
    markers: [],
    options: {
      styles: mapStylesPreset,
    },
    infoPosition: null,
    infoContent: null,
    infoOpened: false,
    infoCurrentKey: null,
    getAPITimeout: null,
    choosedMarker: -1,
  };
}

const computed = {
  infoOptions() {
    const inst = this;
    if (inst.choosedMarker >= 0) {
      return {
        pixelOffset: {
          width: 0,
          height: -10,
        },
        content: `<div class="map-baloon">
                      <h3 class="map-baloon__title">${
                        inst.streets[inst.choosedMarker].name
                      }</h3>
                      <div class="map-baloon__text">
                      <p>${inst.streets[inst.choosedMarker].description}</p>
                      </div>
                      <div class="map-baloon__action">
                      <a href="/books/3?chapter=${
                        inst.streets[inst.choosedMarker].link
                      }" class="button">Читать про улицу</a>
                      </div>
                    </div>`,
      };
    } else {
      return {
        pixelOffset: {
          width: 0,
          height: -10,
        },
        content: `<div class="map-baloon">
                      <h3 class="map-baloon__title">Заголовок</h3>
                      <div class="map-baloon__address">Адрес</div>
                      <div class="map-baloon__text">
                      <p>Краткая справка о здании</p>
                      </div>
                      <div class="map-baloon__action">
                      <a href="#" class="button">Подробнее</a>
                      </div>
                    </div>`,
      };
    }
  },
};

function mounted() {
  this.fetchData();
}

function fetchData() {
  const inst = this;

  new AjaxProvider("streets", "get").request(
    (xhr) => {
      const response = getJSON(xhr.responseText);
      if (xhr.status == 200) {
        inst.streets = response.data;
        let markers = [];

        inst.streets.forEach((street) => {
          let latLng = street.location.split(",");

          markers.push({
            position: {
              lat: parseFloat(latLng[0]),
              lng: parseFloat(latLng[1]),
            },
          });
        });

        inst.markers = markers;
        inst.fetched = true;

        inst.checkAPI();
      } else {
        inst.handleAPIError(response);
      }
    },
    (response) => {
      inst.handleAPIError(response);
    }
  );
}

function checkAPI() {
  const inst = this;

  if (window.google && this.$refs.mapRef) {
    inst.setMapStyle();
  } else {
    if (inst.getAPITimeout) {
      clearTimeout(inst.getAPITimeout);
    }

    inst.getAPITimeout = setTimeout(() => {
      inst.checkAPI();
    }, 500);
  }
}

function markerAction(m, index) {
  const inst = this;
  inst.choosedMarker = index;

  inst.$nextTick(() => {
    inst.infoPosition = m.position;
    inst.infoOpened = true;
    inst.infoCurrentKey = index;
    inst.map.panTo({ lat: m.position.lat, lng: m.position.lng });
  });
}

function clusterAction() {}

function setMapStyle() {
  this.infoWin = new window.google.maps.InfoWindow({
    content: "<div>РРРР</div>",
  });

  let styledMapType = new window.google.maps.StyledMapType(mapStyle, {
    name: "Styled Map",
  });
  this.$refs.mapRef.$mapPromise.then((map) => {
    this.map = map;
    map.mapTypes.set("styled_map", styledMapType);
    map.setMapTypeId("styled_map");
    map.addListener("zoom_changed", () => {
      this.infoOpened = false;
    });
  });
}

export default {
  name,
  data,
  computed,
  mounted,
  methods: {
    fetchData,
    markerAction,
    clusterAction,
    setMapStyle,
    checkAPI,
  },
};
</script>
