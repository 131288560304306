<template>
  <div class="regular-page">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>Фотографии</li>
        </ul>
        <h1 class="regular-page__title">Фотографии</h1>
      </div>
    </div>
    <div class="container">
      <div class="history-album-preview">
        <router-link to="/history-album" class="albums__item">
          <div class="albums__history"></div>
          <div class="albums__info">
            <div class="albums__title">Было - Стало</div>
          </div>
        </router-link>
      </div>
      <div class="albums" v-if="fetched">
        <div class="row mt-20">
          <div
            class="col-m-3 col-s-4 col-x-6"
            v-for="album in albums"
            :key="album.id"
          >
            <AlbumCard :album="album" />
          </div>
        </div>
      </div>
      <Preloader v-else />
      <div class="albums mt-40" v-if="fetched">
        <p class="action-footer__title">Пользовательские альбомы</p>
        <div class="row mt-20" v-if="userAlbums.length">
          <div
            class="col-m-3 col-s-4 col-x-6"
            v-for="album in userAlbums"
            :key="album.id"
          >
            <AlbumCard :album="album" />
          </div>
        </div>
        <p v-else>Еще нет пользовательских альбомов</p>
      </div>
      <Preloader v-else />
      <div class="action-footer">
        <p class="action-footer__title">Поделитесь фотографиями</p>
        <p class="action-footer__text">
          Вы можете пополнить наш архив фотографий своими снимками - после
          проверки модераторами ваш альбом появится на сайте с активной ссылкой
          на автора.
        </p>
        <div class="mt-20">
          <router-link to="/upload-photo" class="button"
            >Загрузить фотографии</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AlbumCard from "@/components/AlbumCard.vue";
import Preloader from "@/components/Preloader.vue";

const name = "Albums";

function data() {
  return {
    fetched: false,
  };
}

const computed = {
  albums() {
    return this.$store.state.albums.items;
  },
  userAlbums() {
    return this.$store.state.albums.userItems;
  },
};

function created() {
  this.fetchData();
}

function fetchData() {
  const inst = this;

  if (!inst.albums.length) {
    inst.$store.dispatch("albums/getAlbums").then(
      () => {
        inst.fetched = true;
      },
      (response) => {
        inst.handleAPIError(response);
      }
    );
  } else {
    inst.fetched = true;
  }
}

export default {
  name,
  data,
  computed,
  created,
  methods: {
    fetchData,
  },
  components: {
    AlbumCard,
    Preloader,
  },
};
</script>
