// initial state
const state = {
  apiLoaded: false,
};

// getters
const getters = {};

// mutations
const mutations = {
  setAPIStatus(state, value) {
    state.apiLoaded = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
