<template>
  <div class="book-page">
    <template v-if="fetched">
      <Reader
        :url="book.epub.path"
        :enable-nav="book.enable_chapters"
        :book-title="book.title"
      />
      <div class="book-preview">
        <div class="container">
          <div class="book-preview__content">
            <div class="book-preview__preview">
              <div class="book-preview__pic">
                <img
                  :src="book.poster.path"
                  :alt="`${book.title}. Автор: Андрей Кропоткин`"
                />
              </div>
              <div v-if="book.download_link" class="book-preview__download">
                <a
                  :href="book.download_link"
                  :download="`${book.title}.epub`"
                  class="link link--download"
                  >Скачать .epub</a
                >
              </div>
            </div>
            <div class="book-preview__info">
              <div class="book-preview__title">
                <h2>
                  {{ book.title }}
                </h2>
              </div>
              <div class="book-preview__text">
                <ul>
                  <li v-for="item in book.meta" :key="item.key">
                    {{ item.key }}: {{ item.value }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="book-description">
        <div class="book-description__content">
          <div class="book-description__title">Описание</div>
          <div class="book-description__text" v-html="book.description"></div>
        </div>
      </div>
      <BookFeedback :book-id="book.id" />
    </template>
    <div v-else class="loader-wrap loader-wrap--static">
      <div class="circle-loader"></div>
    </div>
  </div>
</template>
<script>
import BookFeedback from "@/components/BookFeedback.vue";
import Reader from "@/components/Reader.vue";

function data() {
  return {
    fetched: false,
  };
}

const computed = {
  book() {
    return this.$store.state.books.books.find((item) => {
      return String(item.id) === this.$route.params.id;
    });
  },
};

function created() {
  this.fetchBook();
}

function fetchBook() {
  const inst = this;
  if (inst.book && inst.book.id) {
    inst.fetched = true;
  } else {
    inst.$store.dispatch("books/getBooks").then(
      () => {
        inst.fetched = true;
      },
      (response) => {
        inst.handleAPIError(response);
      }
    );
  }
}

export default {
  name: "BookItem",
  data,
  created,
  computed,
  methods: {
    fetchBook,
  },
  components: {
    Reader,
    BookFeedback,
  },
};
</script>
