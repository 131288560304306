<template>
  <div>
    <ul>
      <Node
        v-for="(item, index) in chapters"
        :key="`item-${index}`"
        :item="item"
        @open="onOpen"
      />
    </ul>
  </div>
</template>
<script>
import Node from "@/components/Node.vue";
const name = "Tree";

const props = {
  chapters: {
    type: Array,
    default: () => {
      return [];
    },
  },
};

function onOpen($event) {
  this.$emit("open", $event);
}

export default {
  name,
  props,
  methods: {
    onOpen,
  },
  components: {
    Node,
  },
};
</script>
