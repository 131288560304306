import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Books from "../views/Books.vue";
import Albums from "../views/Albums.vue";
import Gallery from "../views/Gallery.vue";
import Video from "../views/Video.vue";
import Map from "../views/MapTemp.vue";
// import Map from '../views/Map.vue'
import uploadPhoto from "../views/UploadPhoto";
import historyAlbum from "../views/HistoryAlbum";
import privacyPolicy from "../views/PrivacyPolicy";
import termsUse from "../views/TermsUse";
import BookItem from "../views/BookItem";
import Cycle from "../views/Cycle";
import NotFound from "../views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      header: true,
      title: "Личный сайт Андрея Кропоткина",
    },
  },
  {
    path: "/books",
    name: "books",
    component: Books,
    meta: {
      title: "Книги Андрея Кропоткина",
    },
  },
  {
    path: "/books/:id",
    name: "bookItem",
    component: BookItem,
  },
  {
    path: "/albums",
    name: "albums",
    component: Albums,
    meta: {
      header: true,
      title: "Архив фотографий",
    },
  },
  {
    path: "/albums/:id",
    name: "gallery",
    component: Gallery,
    meta: {
      header: true,
      title: "Галерея фотографий",
    },
  },
  {
    path: "/videos",
    name: "videos",
    component: Video,
    meta: {
      header: true,
      title: "Видеоархив",
    },
  },
  {
    path: "/videos/:id",
    name: "Cycle",
    component: Cycle,
    meta: {
      header: true,
      title: "",
    },
  },
  {
    path: "/map",
    name: "historyMap",
    component: Map,
    meta: {
      noFix: true,
      title: "Карта",
    },
  },
  {
    path: "/upload-photo",
    name: "uploadPhoto",
    component: uploadPhoto,
    meta: {
      title: "Загрузить фотографии",
    },
  },
  {
    path: "/history-album",
    name: "historyAlbum",
    component: historyAlbum,
    meta: {
      title: "Как менялся город - фотографии",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: privacyPolicy,
    meta: {
      title: "Политика конфиденциальности",
    },
  },
  {
    path: "/terms-of-use",
    name: "termsUse",
    component: termsUse,
    meta: {
      title: "Пользовательское соглашение",
    },
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
    meta: {
      title: "Страница не найдена",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.hasOwnProperty("meta") && to.meta.hasOwnProperty("title")) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
