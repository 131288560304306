<template>
  <div class="upload-photo__drop-box" id="dragArea">
    Перетяните ваши .png или .jpg файлы сюда!<br />
    До 20 картинок, максимум 5 MB каждый.
  </div>
</template>
<script>
import Dropzone from "dropzone";
import getJSON from "@/utils/getJSON.js";
import swal from "sweetalert";

Dropzone.autoDiscover = false;

const name = "dropzone";

function mounted() {
  let inst = this;

  const _session_key =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  inst.$store.commit("upload/setSessionKey", _session_key);

  inst.dropzoneInstance = new Dropzone("div#dragArea", {
    url: `api/v1/albums/upload`,
    method: "post",
    paramName: "file",
    createImageThumbnails: false,
    addRemoveLinks: false,
    parallelUploads: 10,
    maxFiles: 20,
    acceptedFiles: "image/png,image/jpg,image/jpeg",
    timeout: 6000000,
    // params: {
    //  mode: inst.modes[inst.$route.params.type],
    // },
  });

  inst.dropzoneInstance
    .on("sending", (file, xhr, formData) => {
      formData.append("_session_key", inst.sessionKey);
      let obj = {
        uuid: file.upload.uuid,
      };
      let reader = new FileReader();
      reader.onload = function (e) {
        obj.url = e.target.result;
        inst.$store.commit("upload/pushFile", obj);
      };
      reader.readAsDataURL(file);
    })
    .on("success", (file) => {
      let response = getJSON(file.xhr.responseText);

      inst.$store.commit("upload/updateFile", {
        uuid: file.upload.uuid,
        response,
      });
    })
    .on("error", (file, error, xhr) => {
      let response = getJSON(xhr.responseText);
      let message = response.error || "Что-то пошло не так";
      inst.$store.commit("upload/removeFile", file.upload.uuid);
      swal("Ошибка", message, "error");
    });
}

const computed = {
  sessionKey() {
    return this.$store.state.upload.sessionKey;
  },
};

function data() {
  return {
    dropzoneInstance: null,
  };
}

export default {
  name,
  computed,
  mounted,
  data,
};
</script>
<style lang="scss">
.dz-preview {
  display: none;
}
</style>
