<template>
  <div>
    <ul class="books mb-40" v-if="fetched">
      <li v-for="book in books" :key="book.id">
        <router-link :to="`/books/${book.id}`">
          <img
            :src="book.poster.path"
            :alt="`Книга '${book.title}'. Автор: Андрей Кропоткин`"
          />
        </router-link>
      </li>
    </ul>
    <Preloader v-else />
  </div>
</template>
<script>
import Preloader from "@/components/Preloader.vue";
const name = "BookTriggers";

function data() {
  return {
    fetched: false,
  };
}

const computed = {
  books() {
    return this.$store.state.books.books;
  },
};

function created() {
  this.fetchData();
}

function fetchData() {
  const inst = this;

  if (!inst.books.length) {
    inst.$store.dispatch("books/getBooks").then(
      () => {
        inst.fetched = true;
      },
      (response) => {
        inst.handleAPIError(response);
      }
    );
  } else {
    inst.fetched = true;
  }
}

export default {
  name,
  data,
  computed,
  created,
  methods: {
    fetchData,
  },
  components: {
    Preloader,
  },
};
</script>
