// initial state
const state = {
  loading: false,
};

// getters
const getters = {};

// mutations
const mutations = {
  setRequest(state, value) {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
