<template>
  <div class="main-page">
    <header class="main-page__header">
      <div class="container">
        <p class="main-page__title">Раскрой историю своего города</p>
        <h1 class="main-page__description">
          Исторический проект о Калининграде
        </h1>
      </div>
    </header>
    <section class="main-page__container container mb-0">
      <h2 class="title">
        «История Калининграда – терра инкогнита для многих его жителей. Но
        только через события прошлого можно осознать настоящее и построить
        успешное будущее»
      </h2>
      <div class="text-block mb-40">
        <p>
          <span class="text-block__first-letter">К</span>алининград – город с
          особенной судьбой. Он пережил многое, каждое событие оставило свой
          след. Богатая довоенная история Кёнигсберга, военные баталии,
          советский период, уникальный современный статус Калининграда – все это
          часть нашей жизни.<br />
          Специфика региона, большой процент переселенцев, смешение культур
          сформировали особую калининградскую идентичность, повлияли на
          коллективную память горожан и процессы зарождения традиций.<br />
          Калининград – один из тех городов, где жители трепетно относятся к
          истории города и испытывают неподдельный интерес к событиям
          прошлого.<br />
          Для того чтобы эта тенденция сохранялась, необходимо обеспечить
          преемственность поколений, суметь заинтересовать людей. Это основная
          идея проекта.
        </p>
      </div>
      <div class="main-page__author">
        <img src="@/assets/andrew.jpg" alt="Андрей Кропоткин" />
      </div>
    </section>
    <div class="accent">
      <h2 class="title title--decoration">
        «Наша задача – сохранить страницы истории. Книга для этого – лучший
        инструмент»
      </h2>
    </div>
    <section class="main-page__container container">
      <div class="text-block">
        <p>
          Началось все с книги, которая показала работу органов местной власти
          за последние 70 лет. Показала то, как эта работа изменила город,
          подняла из пепла и сделала таким, как сегодня.<br />
          Это издание стало началом большого комплексного исследования событий,
          мест и судеб.<br />
          За годы работы удалось собрать действительно бесценную информацию,
          найти уникальные архивные материалы, а главное - увидеть частные
          реликвии и услышать истории, которые стали семейными легендами.<br />
          Все это позволило добиться личного, дружеского характера
          повествования. Отрицание формального, скупого изложения фактов,
          попытка показать, что у истории города есть человеческое лицо, стало
          концепцией всех изданий.<br />
          Особое внимание в это работе уделено визуализации образов,
          иллюстрациям. Читая, вы всегда сможете сопоставить образ с реальным
          местом или личностью.<br />
          Благодаря этому история сходит со страниц и становится частью
          действительности, обретает форму.
        </p>
      </div>
      <BookTriggers />
      <div class="buttons-wrapper">
        <router-link to="/books" class="button">Посмотреть книги</router-link>
      </div>
    </section>
    <div class="main-page__history-pic"></div>
    <section class="main-page__container container">
      <h2 class="title title--decoration">
        «Историю можно назвать отголоском прошлого в будущем: незнающий истории
        обречен на искаженное понимание настоящего»
      </h2>
      <div class="text-block">
        <p>
          Проект создан для сохранения и анализа исторического наследия.<br />
          В большинстве случаев, знания жителей о родном городе и его судьбе
          носят точечный характер. Отдельные места, события и личности
          освещаются широко, но это только малая часть нашего прошлого.<br />
          Мы делаем историю доступной каждому, даем возможность лучше узнать
          город, в котором живем.<br />
          Главное в этой работе – факты и честность перед аудиторией. Подбору и
          проверке информации, работе с архивами и частными коллекциями мы
          относимся с особой серьезностью.<br />
          Но мало рассказать, нужно чтобы тебя услышали и поняли представители
          всех поколений и социокультурных групп. Поэтому мы стараемся
          использовать все доступные способы взаимодействия с аудиторией.
        </p>
      </div>
      <div class="become-gal">
        <div class="become-gal__item">
          <div class="become-gal__pic">
            <img
              src="../assets/gallery/1.jpg"
              alt="Фотография Калининграда времен Кёнигсберга. 1950 год"
            />
          </div>
          <div class="become-gal__year">1950</div>
        </div>
        <div class="become-gal__item">
          <div class="become-gal__pic">
            <img
              src="../assets/gallery/2.jpg"
              alt="Фотография мерии Калининграда"
            />
          </div>
          <div class="become-gal__year">2019</div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <router-link to="/history-album" class="button"
          >Все фотографии «Было-Стало»</router-link
        >
      </div>
      <div class="text-block">
        <p>
          Фотография – способ поймать мгновение. Никакие слова не смогут
          заменить эмоции от увиденного, от узнавания мест, от сравнения того,
          как было и как стало.<br />
          Все издания полны фотоматериалов, большая часть которых увидела свет
          впервые. К сожалению, не все подобранные изображения нашли свое место
          в книгах, но вы всегда сможете увидеть их тут.
        </p>
      </div>
      <div class="prev-gallery">
        <div class="prev-gallery__wrapper">
          <div class="prev-gallery__item">
            <div class="prev-gallery__pic">
              <img src="../assets/gallery/1.jpg" alt="Фотография Кёнигсберга" />
            </div>
          </div>
          <div class="prev-gallery__item prev-gallery__item--bottom">
            <div class="prev-gallery__pic prev-gallery__pic--small">
              <img src="../assets/gallery/3.jpg" alt="Фотография Кёнигсберга" />
            </div>
          </div>
          <div class="prev-gallery__item prev-gallery__item--right">
            <div class="prev-gallery__pic prev-gallery__pic--small">
              <img src="../assets/gallery/4.jpg" alt="Фотография Кёнигсберга" />
            </div>
          </div>
          <div class="prev-gallery__item">
            <div class="prev-gallery__pic">
              <img src="../assets/gallery/5.jpg" alt="Фотография Кёнигсберга" />
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <router-link to="/gallery" class="button"
          >Смотреть все фотографии</router-link
        >
      </div>
    </section>
    <ballons-map />
    <div class="main-page__container container">
      <div class="text-block">
        <p>
          Сборник видео прогулок по Калининграду рассказывает о становлении и
          развитии города с момента штурма Кёнигсберга в 1945 году и до наших
          дней. Каждый ролик наглядно показывает, что история всегда рядом с
          нами. Вместе с ведущим вы пройдете по уникальным местам Калининграда,
          больше узнаете об объектах его культурного наследия, познакомитесь с
          биографиями выдающихся жителей.<br />
          Цикл выходил в формате телевизионных передач на региональном
          телевидении, а сейчас вы можете посмотреть любой из выпусков на нашем
          портале.<br />
          Еще один цикл видео роликов посвящен легендарным личностям, оставившим
          след в истории Янтарного края. Некоторые из них хорошо знакомы
          зрителям, истории других станут для вас открытием.
        </p>
      </div>
    </div>
    <Cycles />
    <Feedback />
  </div>
</template>
<script>
import BookTriggers from "@/components/BookTriggers";
import BallonsMap from "../components/map";
import Feedback from "../components/Feedback";
import Cycles from "@/components/Cycles";

export default {
  name: "home",
  components: {
    BallonsMap,
    Feedback,
    BookTriggers,
    Cycles,
  },
};
</script>
