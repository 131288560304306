<template>
  <li class="node-tree">
    <a class="node-tree__link" href="#" @click.prevent="onClick">{{
      item.label
    }}</a>
    <ul v-if="item.subitems && item.subitems.length">
      <node
        v-for="(child, index) in item.subitems"
        :item="child"
        :key="`child-${index}`"
        @open="onOpen"
      ></node>
    </ul>
  </li>
</template>
<script>
const name = "Node";

const props = {
  item: {
    type: Object,
    default: () => {
      return {};
    },
  },
};

function onOpen($event) {
  this.$emit("open", $event);
}

function onClick() {
  this.$emit("open", this.item.href);
}

export default {
  name,
  props,
  methods: {
    onOpen,
    onClick,
  },
};
</script>
<style lang="scss">
.node-tree {
  ul {
    padding-left: 18px;
  }
}
.node-tree__link {
  color: #737687;
  text-decoration: underline;
  font-size: 18px;
  line-height: 1.45;
  margin-bottom: 6px;
  &:hover {
    color: #ffb800;
  }
}
</style>
