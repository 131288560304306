<template>
  <div class="video-gallery">
    <section
      class="video-gallery__section"
      v-for="cycle in cycles"
      :key="cycle.id"
    >
      <h3 class="video-gallery__title">
        Видеоцикл
        <router-link :to="`/videos/${cycle.id}`"
          >«{{ cycle.title }}»</router-link
        >
      </h3>
      <div class="container">
        <div class="row">
          <div
            class="col-s-6 col-x-12"
            v-for="video in cycle.videos.slice(0, 4)"
            :key="video.id"
          >
            <VideoItem :video="video" @click="openVideo(video.link)" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="buttons-wrapper">
          <router-link
            :to="`/videos/${cycle.id}`"
            class="button button--reverse"
            >Смотреть весь цикл</router-link
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import VideoItem from "@/components/VideoItem";
const name = "Cycles";

function data() {
  return {
    fetched: false,
    index: null,
  };
}

const computed = {
  cycles() {
    return this.$store.state.cycles.cycles;
  },
  videos() {
    return this.$store.state.cycles.videos;
  },
};

function created() {
  this.fetchData();
}

function fetchData() {
  const inst = this;
  if (inst.cycles.length) {
    inst.fetched = true;
  } else {
    inst.$store.dispatch("cycles/getCycles").then(
      () => {
        inst.fetched = true;
      },
      (response) => {
        inst.handleAPIError(response);
      }
    );
  }
}

function openVideo(link) {
  this.$store.commit("cycles/setOpenedVideo", link);
}

export default {
  name,
  data,
  created,
  computed,
  methods: {
    fetchData,
    openVideo,
  },
  components: {
    VideoItem,
  },
};
</script>
