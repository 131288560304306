// initial state
const state = {
  sessionKey: "",
  files: [],
  counter: 0,
};

// getters
const getters = {};

// mutations
const mutations = {
  setSessionKey(state, value) {
    state.sessionKey = value;
  },
  pushFile(state, value) {
    state.files.push(value);
  },
  updateFile(state, payload) {
    let i = 0,
      found = -1;
    while (i < state.files.length && found < 0) {
      if (state.files[i].uuid == payload.uuid) {
        found = i;
      }
      i++;
    }
    if (found >= 0) {
      state.files[found].model = payload.response;
      state.counter++;
    }
  },
  removeFile(state, uuid) {
    let i = 0,
      found = -1;

    while (i < state.files.length && found < 0) {
      if (state.files[i].uuid == uuid) {
        found = i;
      }
      i++;
    }
    if (found >= 0) {
      state.files.splice(found, 1);
      state.counter++;
    }
  },
  removeByIndex(state, index) {
    state.files.splice(index, 1);
    state.counter++;
  },
  reset(state) {
    state.files = [];
    state.counter = 0;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
