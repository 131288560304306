<template>
  <div>
    <div class="book-page__header">
      <div class="book-page__header-container container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/"> Главная </router-link>
          </li>
          <li>
            <router-link to="/books"> Книги </router-link>
          </li>
          <li>
            {{ bookTitle }}
          </li>
        </ul>
        <div
          class="book-container"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
        >
          <div class="book-page__preview" id="bookArea" ref="reader"></div>
          <div
            @click.prevent="onPrevPage"
            class="book__page-button book__page-button--prev"
          >
            <SvgIcon name="angle" />
          </div>
          <div
            @click.prevent="onNextPage"
            class="book__page-button book__page-button--next"
          >
            <SvgIcon name="angle" />
          </div>
        </div>
        <div class="book-footer" v-if="enableNav">
          <a
            href="#"
            class="book-footer__chapter-link"
            @click.prevent="onOpenChapter(nextSection.href)"
            >{{ nextSection.label }}</a
          >
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content-list">
        <div class="content-list__title">
          <p>Содержание</p>
        </div>
        <Tree :chapters="chapters" @open="onOpenChapter" />
      </div>
    </div>
  </div>
</template>
<script>
const name = "Reader";

import SvgIcon from "@/components/SvgIcon.vue";
import Tree from "@/components/Tree.vue";

function data() {
  return {
    book: {},
    rendition: {},
    prevSection: {
      label: "",
      href: "",
    },
    nextSection: {
      label: "",
      href: "",
    },
    chapters: [],
    touchDetecting: false,
    touchData: {},
    touchMoving: false,
    downX: 0,
  };
}

const props = {
  enableNav: {
    type: [Boolean, Number],
    default: 1,
  },
  url: {
    type: String,
    default: "",
  },
  bookTitle: {
    type: String,
    default: "",
  },
};

const computed = {
  apiLoaded() {
    return this.$store.state.reader.apiLoaded;
  },
};

function mounted() {
  this.getAPI();
}

function getAPI() {
  const inst = this;

  if (inst.apiLoaded) {
    inst.initBook();
  } else {
    const epubJS = document.createElement("SCRIPT");

    epubJS.setAttribute(
      "src",
      "/themes/akropotkin/resources/vendor/epub.min.js"
    );
    epubJS.setAttribute("async", "");
    epubJS.setAttribute("defer", "");
    document.head.appendChild(epubJS);

    epubJS.onload = function () {
      inst.$store.commit("reader/setAPIStatus", true);
      inst.initBook();
    };
  }
}

function initBook() {
  const inst = this;
  inst.book = window.ePub(inst.url);
  inst.rendition = inst.book.renderTo("bookArea", {
    flow: "auto",
    width: "100%",
    height: "100%",
  });

  inst.rendition.display();

  inst.book.loaded.navigation.then(function (toc) {
    toc.forEach(function (chapter) {
      inst.chapters.push(chapter);
    });
    if (inst.$route.query.chapter) {
      inst.rendition.display(inst.$route.query.chapter + inst.$route.hash);
    }
  });

  if (inst.enableNav) {
    inst.rendition.on("rendered", function (section) {
      let nextSection = section.next();
      let prevSection = section.prev();

      if (nextSection) {
        let nextNav, nextLabel;
        nextNav = inst.book.navigation.get(nextSection.href);
        if (nextNav) {
          nextLabel = nextNav.label + " »" || "";

          inst.nextSection = {
            href: nextNav.href,
            label: nextLabel,
          };
        } else {
          inst.nextSection = {
            href: "",
            label: "",
          };
        }
      }

      if (prevSection) {
        let prevNav, prevLabel;
        prevNav = inst.book.navigation.get(prevSection.href);
        if (prevNav) {
          prevLabel = prevNav.label || "";

          inst.prevSection = {
            href: prevNav.href,
            label: prevLabel,
          };
        } else {
          inst.prevSection = {
            href: "",
            label: "",
          };
        }
      }
    });
  }
}

function onOpenChapter(href) {
  const inst = this;
  this.rendition.display(href);
  let { top } = inst.$refs.reader.getBoundingClientRect();
  if (top < 0) {
    top += pageYOffset - 110;
    window.scrollTo(0, top);
  }
}

function onNextPage() {
  this.rendition.next();
}

function onPrevPage() {
  this.rendition.prev();
}

function onTouchStart($event) {
  const inst = this;
  if ($event.touches.length == 1) {
    inst.touchDetecting = true;

    inst.touchData.touch = $event.changedTouches[0];

    inst.touchData.x = inst.touchData.touch.pageX;
    inst.touchData.y = inst.touchData.touch.pageY;

    document.addEventListener("touchend", inst.onTouchEnd);
    document.addEventListener("touchcancel", inst.onTouchEnd);
  }
}

function onTouchMove($event) {
  const inst = this;

  if (
    inst.touchDetecting &&
    $event.changedTouches[0].identifier == inst.touchData.touch.identifier
  ) {
    if (inst.touchMoving) {
      $event.preventDefault();
    }
    let movementX, movementY;
    if (!inst.touchMoving) {
      const newX = $event.changedTouches[0].pageX;
      const newY = $event.changedTouches[0].pageY;

      movementX = newX - inst.touchData.x;
      movementY = newY - inst.touchData.y;

      if (Math.abs(movementX) >= Math.abs(movementY)) {
        $event.preventDefault();
        inst.touchMoving = true;
      } else {
        inst.touchDetecting = false;
      }
    }
  } else {
    inst.touchDetecting = false;
  }
}

function onTouchEnd($event) {
  const inst = this;
  if (
    inst.touchDetecting &&
    $event.changedTouches[0].identifier == inst.touchData.touch.identifier &&
    inst.touchMoving
  ) {
    const newX = $event.changedTouches[0].pageX;
    const movementX = newX - inst.touchData.x;
    inst.changePage(movementX);
    inst.touchDetecting = false;
    inst.touchMoving = false;
    document.removeEventListener("touchend", inst.onTouchEnd);
    document.removeEventListener("touchcancel", inst.onTouchEnd);
  }
}

function changePage(delta) {
  const inst = this;

  if (delta > 0) {
    inst.rendition.prev();
  } else {
    inst.rendition.next();
  }
}

export default {
  name,
  data,
  props,
  mounted,
  computed,
  methods: {
    getAPI,
    initBook,
    onNextPage,
    onPrevPage,
    onOpenChapter,
    onTouchStart,
    onTouchMove,
    changePage,
    onTouchEnd,
  },
  components: {
    SvgIcon,
    Tree,
  },
};
</script>
