import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";

// initial state
const state = {
  items: [],
  userItems: [],
  paginationData: {
    currentPage: 1,
    lastPage: 1,
  },
};

// getters
const getters = {};

// mutations
const mutations = {
  setItems(state, payload) {
    state.items = payload.albums;
    state.userItems = payload.userAlbums;
  },
  setPaginationData(state, payload) {
    Object.assign(state.paginationData, payload);
  },
};

const actions = {
  getAlbums({ commit }) {
    return new Promise((resolve, reject) => {
      new AjaxProvider("albums", "get", {
        with_count: 1,
      }).request((xhr) => {
        const response = getJSON(xhr.responseText);
        if (xhr.status == 200) {
          const userAlbums = [];
          const albums = [];

          response.data.forEach((album) => {
            if (album.is_user) {
              userAlbums.push(album);
            } else {
              albums.push(album);
            }
          });

          commit("setItems", {
            albums,
            userAlbums,
          });
          commit("setPaginationData", {
            currentPage: response.currentPage,
            lastPage: response.last_page,
          });

          if (typeof resolve === "function") {
            resolve();
          }
        } else {
          if (typeof reject === "function") {
            reject(response);
          }
        }
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
