export default class AjaxProvider {
  // ?_method=PUT - add for PUT method

  /*
   * path (String) - url path
   * mathod (String) - request method
   * data (object) - form data for the request
   */
  constructor(path, method = "post", data = {}) {
    var inst = this;
    /*
     * Validate handler name
     */
    if (path === undefined) {
      throw new Error("The request path name is not specified.");
    }

    if (typeof data !== "object") throw new Error("Invalid data format.");

    if (path.search("http") >= 0) {
      inst.path = path;
    } else {
      inst.path = `/api/v1/${path}`;
    }
    inst.method = method.toLowerCase();

    inst.requestHeaders = {
      "X-Requested-With": "XMLHttpRequest",
    };

    if (localStorage.getItem("token"))
      inst.requestHeaders.Authorization =
        "Bearer " + localStorage.getItem("token");

    if (inst.method == "post" || inst.method == "put") {
      inst.requestHeaders["Content-Type"] = "application/json; charset=utf-8";
      inst.formData = JSON.stringify(data);
    } else {
      if (!Object.entries)
        Object.entries = function (obj) {
          var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
          while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

          return resArray;
        };

      inst.formData = {};

      var params = Object.entries(data)
        .map((e) => {
          if (Array.isArray(e[1])) {
            let str = "";
            let name = e[0] + "[]";
            e[1].forEach((value, index) => {
              str += name + "=" + value;
              if (index < e[1].length - 1) {
                str += "&";
              }
            });
            return [str];
          } else {
            return e.join("=");
          }
        })
        .join("&");

      if (params.length) inst.path += `?${params}`;
    }
  }

  /*
   *  sending request
   *  @var callback (function) - callback for ajaxRequest
   */
  request(callback = null) {
    var xhr = new XMLHttpRequest(),
      inst = this;
    xhr.open(inst.method, inst.path, true);

    var headerKeys = Object.keys(this.requestHeaders);

    for (var i = 0; i < headerKeys.length; i++) {
      xhr.setRequestHeader(headerKeys[i], this.requestHeaders[headerKeys[i]]);
    }

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        typeof callback == "function" && callback(xhr);
      }
    };

    if (Object.keys(this.formData).length) xhr.send(this.formData);
    else xhr.send();
  }
}
