<template>
  <div id="app">
    <div class="loader-wrap" v-if="loading">
      <div class="circle-loader"></div>
    </div>
    <Modal v-if="openedVideo" @close="closeVideo">
      <youtube :video-id="openedVideo" />
    </Modal>
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import appHeader from "./components/header";
import appFooter from "./components/footer";
import Modal from "./components/Modal";

const computed = {
  loading() {
    return this.$store.state.common.loading;
  },
  openedVideo() {
    return this.$store.state.cycles.openedVideo;
  },
};

function closeVideo() {
  this.$store.commit("cycles/setOpenedVideo", false);
}
export default {
  name: "history-app",
  computed,
  watch: {
    $route: function () {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    closeVideo,
  },
  components: {
    appFooter,
    appHeader,
    Modal,
  },
};
</script>

<style lang="scss">
@import "styles/main";
</style>
