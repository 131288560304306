import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";

// initial state
const state = {
  cycles: [],
  openedVideo: false,
};

// getters
const getters = {};

// mutations
const mutations = {
  setCycles(state, value) {
    state.cycles = value;
  },
  setOpenedVideo(state, value) {
    state.openedVideo = value;
  },
};

const actions = {
  getCycles({ commit }) {
    return new Promise((resolve, reject) => {
      new AjaxProvider("cycles", "get").request((xhr) => {
        const response = getJSON(xhr.responseText);
        if (xhr.status == 200) {
          commit("setCycles", response.data);
          if (typeof resolve === "function") {
            resolve();
          }
        } else {
          if (typeof reject === "function") {
            reject(response);
          }
        }
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
