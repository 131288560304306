<template>
  <div class="books-page">
    <div class="container">
      <ul class="breadcrumbs">
        <li>
          <router-link to="/">Главная</router-link>
        </li>
        <li>Книги</li>
      </ul>
      <section class="books-page__header">
        <h1 class="books-page__title">Книги длиною в историю</h1>
        <div class="books-page__header-container">
          <p class="books-page__description">
            Книги-мозаики, собранные из историй о городе, людях и местах
          </p>
          <blockquote class="books-page__quote">
            <p>
              «Не знающий истории обречен на искаженное понимание настоящего».
            </p>
          </blockquote>
        </div>
      </section>
    </div>
    <section
      class="book book--blue"
      v-if="bookKaliningrad.hasOwnProperty('id')"
    >
      <div class="container book__content">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/1">
                <img
                  :src="bookKaliningrad.poster.path"
                  alt="Книга '70 лет городу Калининград'. Автор: Андрей Кропоткин"
                />
              </router-link>
            </div>
            <!--             <div class="book__download" v-if="bookKaliningrad.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="text-block" v-html="bookKaliningrad.promo"></div>
            <div class="actions">
              <router-link class="button" to="/books/1">Читать</router-link>
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="bookKaliningrad.download_link"
                v-if="bookKaliningrad.download_link"
                >Скачать PDF</a
              >
            </div>
          </div>
        </div>
        <blockquote class="quote book__quote">
          <p class="quote__content">
            Надеюсь, издание станет не только отличным помощником школьникам,
            студентам, преподавателям, но и желанной реликвией для тех, кому она
            посвящена, - старшего поколения калининградцев.
          </p>
          <footer>
            <cite class="quote__author">Андрей Кропоткин</cite>
          </footer>
        </blockquote>
      </div>
    </section>
    <div v-else class="loader-wrap loader-wrap--static">
      <div class="circle-loader"></div>
    </div>
    <template
      v-if="
        bookKaliningrad.hasOwnProperty('id') && bookKaliningrad.reviews.length
      "
    >
      <Review
        v-for="(review, index) in bookKaliningrad.reviews"
        :key="`review-${index}`"
        :review="review"
      />
    </template>
    <div class="book book--violet" v-if="legendsBook.hasOwnProperty('id')">
      <div class="container book__container">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/2">
                <img
                  :src="legendsBook.poster.path"
                  alt="Книга 'Легенды Янтарного края'. Автор: Андрей Кропоткин"
                />
              </router-link>
            </div>
            <!--             <div class="book__download" v-if="legendsBook.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="text-block" v-html="legendsBook.promo"></div>
            <div class="actions">
              <router-link class="button" to="/books/2">Читать</router-link>
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="legendsBook.download_link"
                v-if="legendsBook.download_link"
                >Скачать PDF</a
              >
            </div>
          </div>
        </div>
        <div class="book__title">Персоналии</div>
      </div>
      <div
        class="container container--hidden mb-40"
        v-if="legendsBook.chapters.length"
      >
        <div class="book__persons">
          <router-link
            :to="'/books/2?chapter=' + chapter.link"
            target="_blank"
            v-for="(chapter, key) in legendsBook.chapters.slice(
              0,
              maxLegendsChapters
            )"
            :key="key"
            >{{ chapter.chapter }}</router-link
          >
        </div>
        <div
          class="book__actions buttons-wrapper"
          v-if="maxLegendsChapters < legendsBook.chapters.length"
        >
          <a
            href="#"
            class="button button--reverse"
            @click.prevent="incrementMaxChapters"
            >Показать ещё</a
          >
        </div>
      </div>
    </div>
    <div v-else class="loader-wrap loader-wrap--static">
      <div class="circle-loader"></div>
    </div>
    <template
      v-if="legendsBook.hasOwnProperty('id') && legendsBook.reviews.length"
    >
      <Review
        v-for="(review, index) in legendsBook.reviews"
        :key="`review-1-${index}`"
        :review="review"
      />
    </template>
    <div
      class="book book--gray book--reverse"
      v-if="walksBook.hasOwnProperty('id')"
    >
      <div class="container book__container">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/3">
                <img
                  :src="walksBook.poster.path"
                  alt="Книга 'Прогулки по Калининград'. Автор: Андрей Кропоткин"
                />
              </router-link>
            </div>
            <!--             <div class="book__download" v-if="walksBook.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="book__title">Новинка</div>
            <div class="text-block" v-html="walksBook.promo"></div>
            <div class="actions">
              <router-link class="button" to="/books/3">Читать</router-link>
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="walksBook.download_link"
                v-if="walksBook.download_link"
                >Скачать PDF</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader-wrap loader-wrap--static">
      <div class="circle-loader"></div>
    </div>
    <ballons-map class="map-info--sec" v-if="walksBook.hasOwnProperty('id')" />
    <template v-if="walksBook.hasOwnProperty('id') && walksBook.reviews.length">
      <Review
        v-for="(review, index) in walksBook.reviews"
        :key="`review-2-${index}`"
        :review="review"
      />
    </template>
    <div class="book book--violet" v-if="assaultBook.hasOwnProperty('id')">
      <div class="container book__container">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/2">
                <img
                  :src="assaultBook.poster.path"
                  alt="Книга 'Герои штурма Кенигсберга'. Автор: Андрей Кропоткин"
                />
              </router-link>
            </div>
            <!--  <div class="book__download" v-if="assaultBook.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="text-block high-line" v-html="assaultBook.promo"></div>
            <div class="actions">
              <router-link class="button" :to="`/books/${assaultBook.id}`"
                >Читать</router-link
              >
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="assaultBook.download_link"
                v-if="assaultBook.download_link"
                >Скачать PDF</a
              >
            </div>
          </div>
        </div>
        <div class="book__title">Персоналии</div>
      </div>
      <div
        class="container container--hidden mb-40"
        v-if="assaultBook.chapters.length"
      >
        <div class="book__persons">
          <router-link
            :to="'/books/' + assaultBook.id + '?chapter=' + chapter.link"
            target="_blank"
            v-for="(chapter, key) in assaultBook.chapters.slice(
              0,
              maxAssaultChapters
            )"
            :key="key"
            >{{ chapter.chapter }}</router-link
          >
        </div>
        <div
          class="book__actions buttons-wrapper"
          v-if="maxAssaultChapters < assaultBook.chapters.length"
        >
          <a
            href="#"
            class="button button--reverse"
            @click.prevent="incrementMaxChapters('Assault')"
            >Показать ещё</a
          >
        </div>
      </div>
    </div>
    <div v-else class="loader-wrap loader-wrap--static">
      <div class="circle-loader"></div>
    </div>
    <template
      v-if="assaultBook.hasOwnProperty('id') && assaultBook.reviews.length"
    >
      <Review
        v-for="(review, index) in assaultBook.reviews"
        :key="`review-3-${index}`"
        :review="review"
      />
    </template>
    <section class="book book--blue" v-if="regionBook.hasOwnProperty('id')">
      <div class="container book__content">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/1">
                <img
                  :src="regionBook.poster.path"
                  alt="Книга 'Регион 39'. Автор: Андрей Кропоткин"
                />
              </router-link>
            </div>
            <!--             <div class="book__download" v-if="bookKaliningrad.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="text-block" v-html="regionBook.promo"></div>
            <div class="actions">
              <router-link class="button" to="/books/5">Читать</router-link>
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="regionBook.download_link"
                v-if="regionBook.download_link"
                >Скачать PDF</a
              >
            </div>
          </div>
        </div>
        <blockquote class="quote book__quote">
          <p class="quote__content">
            Надеюсь, издание станет не только отличным помощником школьникам,
            студентам, преподавателям, но и желанной реликвией для тех, кому она
            посвящена, - старшего поколения калининградцев.
          </p>
          <footer>
            <cite class="quote__author">Андрей Кропоткин</cite>
          </footer>
        </blockquote>
        <div class="book__title">Города</div>
      </div>
      <div
        class="container container--hidden mb-40"
        v-if="regionBook.chapters.length"
      >
        <div class="book__persons">
          <router-link
            :to="'/books/5?chapter=' + chapter.link"
            target="_blank"
            v-for="(chapter, key) in regionBook.chapters.slice(
              0,
              maxRegionChapters
            )"
            :key="key"
            >{{ chapter.chapter }}</router-link
          >
        </div>
        <div
          class="book__actions buttons-wrapper"
          v-if="maxRegionChapters < regionBook.chapters.length"
        >
          <a
            href="#"
            class="button button--reverse"
            @click.prevent="incrementMaxChapters('Region')"
            >Показать ещё</a
          >
        </div>
      </div>
    </section>
    <div v-else class="loader-wrap loader-wrap--static">
      <div class="circle-loader"></div>
    </div>
    <div
      class="book book--gray book--reverse"
      v-if="walksSecondBook.hasOwnProperty('id')"
    >
      <div class="container book__container">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/6">
                <img
                  :src="walksSecondBook.poster.path"
                  alt="Книга 'Прогулки по Калининграду'. Автор: Андрей Кропоткин"
                />
              </router-link>
            </div>
            <!--             <div class="book__download" v-if="walksBook.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="book__title">Второй том Прогулок по Калининграду</div>
            <div class="text-block" v-html="walksSecondBook.promo"></div>
            <div class="actions">
              <router-link class="button" to="/books/6">Читать</router-link>
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="walksSecondBook.download_link"
                v-if="walksSecondBook.download_link"
                >Скачать PDF</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader-wrap loader-wrap--static">
      <div class="circle-loader"></div>
    </div>
    <div
      class="book book--gray book--reverse"
      v-if="walksThirdBook.hasOwnProperty('id')"
    >
      <div class="container book__container">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/7">
                <img
                  :src="walksThirdBook.poster.path"
                  :alt="`Книга '${walksThirdBook.title}'. Автор: Андрей Кропоткин`"
                />
              </router-link>
            </div>
            <!--             <div class="book__download" v-if="walksBook.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="text-block" v-html="walksThirdBook.promo"></div>
            <div class="actions">
              <router-link class="button" to="/books/7">Читать</router-link>
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="walksThirdBook.download_link"
                v-if="walksThirdBook.download_link"
                >Скачать Epub</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="book book--gray book--reverse"
      v-if="kenigBook.hasOwnProperty('id')"
    >
      <div class="container book__container">
        <div class="book__info">
          <div class="book__preview-block">
            <div class="book__preview">
              <router-link to="/books/8">
                <img
                  :src="kenigBook.poster.path"
                  :alt="`Книга '${kenigBook.title}'. Автор: Андрей Кропоткин`"
                />
              </router-link>
            </div>
            <!--             <div class="book__download" v-if="walksBook.download_link">
              <a href="#" target="_blank" class="link link--download">pdf</a>
            </div> -->
          </div>
          <div class="book__text">
            <div class="book__title">Новинка</div>
            <div class="text-block" v-html="kenigBook.promo"></div>
            <div class="actions">
              <router-link class="button" to="/books/7">Читать</router-link>
              <a
                target="_blank"
                rel="nofollow"
                class="button button--white"
                :href="kenigBook.download_link"
                v-if="kenigBook.download_link"
                >Скачать Epub</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <GuessForm />
  </div>
</template>
<script>
import BallonsMap from "../components/map";
import GuessForm from "../components/GuessForm";

import Review from "@/components/Review.vue";

const name = "Books";

function data() {
  return {
    maxLegendsChapters: 10,
    maxAssaultChapters: 10,
    maxRegionChapters: 10,
  };
}

const computed = {
  bookKaliningrad() {
    return this.$store.state.books.kaliningrad;
  },
  legendsBook() {
    return this.$store.state.books.legends;
  },
  walksBook() {
    return this.$store.state.books.walks;
  },
  assaultBook() {
    return this.$store.state.books.assault;
  },
  regionBook() {
    return this.$store.state.books.region;
  },
  walksSecondBook() {
    return this.$store.state.books.walksSecond;
  },
  walksThirdBook() {
    return this.$store.state.books.walksThird;
  },
  kenigBook() {
    return this.$store.state.books.kenig;
  },
};

function created() {
  this.fetchBooks();
}

function fetchBooks() {
  const inst = this;

  if (
    !inst.bookKaliningrad.hasOwnProperty("id") ||
    !inst.legendsBook.hasOwnProperty("id") ||
    !inst.walksBook.hasOwnProperty("id") ||
    !inst.regionBook.hasOwnProperty("id") ||
    !inst.walksSecondBook.hasOwnProperty("id") ||
    !inst.walksThirdBook.hasOwnProperty("id") ||
    !inst.kenigBook.hasOwnProperty("id")
  ) {
    inst.$store.dispatch("books/getBooks");
  }
}

function incrementMaxChapters(book = "Legends") {
  let name = "max" + book + "Chapters";
  this[name] += 5;
}

export default {
  name,
  data,
  computed,
  created,
  methods: {
    fetchBooks,
    incrementMaxChapters,
  },
  components: {
    GuessForm,
    BallonsMap,
    Review,
  },
};
</script>
