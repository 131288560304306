<template>
  <blockquote class="quote container" :key="review.id">
    <p class="quote__content">«{{ review.text }}»</p>
    <footer>
      <cite class="quote__author">
        {{ review.name }}
      </cite>
    </footer>
  </blockquote>
</template>
<script>
const name = "Review";

const props = {
  review: {
    type: Object,
    default: () => {
      return {};
    },
  },
};

export default {
  name,
  props,
};
</script>
