/*
Usage:

 let validation = new Validator(inst.formData, inst.validationRules).make()
 // update local errors state
inst.errors = validation.errors
// check validation results
if(!validation.isValid) {
    // turn on deep watching of formData Object
    inst.validationRequest = true
    return
}

ValidationRules should have the same structure as FormData is

Supported rules:

- email
- required
- confirm
- min (should contain value, syntax is - "min:5" )

 */

export default class Validator {
  constructor(formData, rules) {
    this.formData = formData;
    this.rules = rules;

    this.errors = {};

    this.valid = true;

    this.errorMessages = {
      required: "Обязательное поле",
      email: "Некорректный E-mail",
      min: ":min символов минимум",
      confirm: "Необходимо ваше подтверждение",
      digits: "Минимум :digits цифр",
      number: "Должны быть только цифры",
      same_as: "Не совпадает",
      max: "Максимальное значение - :max",
      minimum: "Минимальное значение - :minimum",
    };
  }

  /*
   * Test e-mail in formData
   * @param propName {String} - Property name in this.formData to check
   * @return Boolean
   */
  email(propName) {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.formData[propName]);
  }

  /*
   * Test min size of value
   * @param propName {String} - property name in formData
   * @param propValue {Integer} - property value
   * @return Boolean
   */
  min(propName, propValue) {
    if (!isNaN(propValue)) {
      return this.formData[propName].length >= parseInt(propValue);
    }

    return false;
  }
  /*
   * Test max size of value
   * @param propName {String} - property name in formData
   * @param propValue {Integer} - property value
   * @return Boolean
   */
  max(propName, propValue) {
    if (!isNaN(propValue)) {
      return this.formData[propName].length <= parseInt(propValue);
    }

    return false;
  }

  /*
   * Test max value of value
   * @param propName {String} - property name in formData
   * @param propValue {Integer} - property value
   * @return Boolean
   */
  maximum(propName, propValue) {
    if (!isNaN(propValue)) {
      return parseInt(this.formData[propName]) <= parseInt(propValue);
    }

    return false;
  }

  /*
   * Test min value of value
   * @param propName {String} - property name in formData
   * @param propValue {Integer} - property value
   * @return Boolean
   */
  minimum(propName, propValue) {
    if (this.formData[propName] && !isNaN(propValue)) {
      return parseInt(this.formData[propName]) >= parseInt(propValue);
    }

    return true;
  }

  /*
   * Test required field
   * @param propName {String} - property name in formData
   * @return Boolean
   */
  required(propName) {
    let val = this.formData[propName];

    if (Array.isArray(val)) {
      return val.length > 0;
    } else if (typeof val == "object") {
      if (Object.prototype.toString.call(val) === "[object Date]") {
        return true;
      }
    } else {
      return val !== "" && val !== false;
    }
  }

  /*
   * Test digits rule
   * @param propName {String} - property name in formData
   * @param len {Number|String} - target length of field
   * @return Boolean
   */
  digits(propName, len) {
    let val = this.formData[propName];

    if (isNaN(val)) {
      return false;
    }

    let length = parseInt(len);
    let str = val + "";
    if (str.length !== length) {
      return false;
    }

    return true;
  }

  /*
   * Special test to get Cleave-field value for expiration date of credit card. Actually 6 digits
   * @param propName {String} - property name in formData
   * @return Boolean
   */
  expirationDate(propName) {
    let val = this.formData[propName];
    let str = val + "";
    return str.length == 4;
  }

  /*
   * Test confirmation field
   * @param propName {String} - property name in formData
   * @return Boolean
   */
  confirm(propName) {
    return this.formData[propName] !== "" && this.formData[propName] !== false;
  }

  /*
   * Test number (empty value returns true)
   * @param propName {String} - property name in formData
   * @return Boolean
   */
  number(propName) {
    let val = this.formData[propName];

    if (val == "") return true;

    return !isNaN(val);
  }

  /*
   * Test if value is the same (e.g. for password_confirmation same_as:password)
   * @param propName {String} - property name in formData
   * @param propValue {String} - property name in formData (test with it_)
   * @return Boolean
   */
  same_as(propName, propValue) {
    let val = this.formData[propName],
      targetValue = this.formData[propValue];

    return targetValue.length && val == targetValue;
  }

  postcode(propName) {
    let val = this.formData[propName];

    return val.length > 0
      ? !isNaN(val) &&
          val.length === 5 &&
          val[0] + val[1] < 53 &&
          val[0] + val[1] > 0
      : true;
  }

  /*
   * Makes validation of formData class property
   * Returns errors Object {isValid: Boolean with validation result, errors: Object based on formData Structure }
   */
  make() {
    let inst = this;

    for (let prop in inst.formData) {
      if (inst.rules[prop]) {
        let propRules = inst.rules[prop].split("|");

        let failed = false,
          i = 0;

        while (i < propRules.length && !failed) {
          let rulerArr = propRules[i].split(":");
          if (typeof inst[rulerArr[0]] == "function") {
            let test = inst[rulerArr[0]](prop, rulerArr[1]);
            if (!test) {
              inst.valid = false;
              failed = true;
              inst.errors[prop] = inst.getErrorText(rulerArr[0], rulerArr[1]);
            } else {
              inst.errors[prop] = "";
            }
          }
          i++;
        }
      }
    }

    return {
      isValid: inst.valid,
      errors: inst.errors,
    };
  }

  /*
   * Get error text for interface
   * @param prop {String} - property in Formdata
   * @param ruleName {String} - name of rule which is not valid
   * @param ruleValue {String|Boolean|Number} (optional) - Value of rule. e.g. Minimal size for "min" rule
   */
  getErrorText(ruleName, ruleValue) {
    let value = typeof ruleValue == "undefined" ? "" : ruleValue;
    return this.errorMessages[ruleName].replace(":" + ruleName, value);
  }
}
