import swal from "sweetalert";

function handleAPIError(response) {
  const message = response.error || "Что-то пошло не так, попробуйте позже";
  swal("Ошибка", message, "error");
}

export default {
  methods: {
    handleAPIError,
  },
};
