<template>
  <div class="preloader">
    <div class="circ1"></div>
    <div class="circ2"></div>
    <div class="circ3"></div>
    <div class="circ4"></div>
  </div>
</template>
<script>
const name = "Preloader";
export default {
  name,
};
</script>
