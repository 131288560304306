import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";

// initial state
const state = {
  items: [],
  paginationData: {
    currentPage: 1,
    lastPage: 1,
  },
};

// getters
const getters = {};

// mutations
const mutations = {
  setItems(state, value) {
    state.items = value;
  },
  setPaginationData(state, payload) {
    Object.assign(state.paginationData, payload);
  },
};

const actions = {
  getPics({ commit }) {
    return new Promise((resolve, reject) => {
      new AjaxProvider("dualpics", "get").request((xhr) => {
        const response = getJSON(xhr.responseText);
        if (xhr.status == 200) {
          commit("setItems", response.data);
          commit("setPaginationData", {
            currentPage: response.currentPage,
            lastPage: response.last_page,
          });

          if (typeof resolve === "function") {
            resolve();
          }
        } else {
          if (typeof reject === "function") {
            reject(response);
          }
        }
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
