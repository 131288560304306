<template>
  <div class="regular-page regular-page--dark">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>Страница не найдена</li>
        </ul>
        <div class="regular-page__description">
          <h1 class="regular-page__description-title">Страница не найдена</h1>
          <p class="mb-40">
            Зато есть книги, на которые стоит обратить внимание
          </p>
          <BookTriggers />
        </div>
      </div>
    </div>
    <Cycles />
  </div>
</template>
<script>
const name = "Not found";

import BookTriggers from "@/components/BookTriggers";

export default {
  name,
  components: {
    BookTriggers,
  },
};
</script>
