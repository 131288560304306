<template>
  <router-link :to="`/albums/${album.id}`" class="albums__item">
    <div
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      class="progress-bar"
      v-if="!isCoverLoaded"
    >
      <div class="progress-bar__bar"></div>
      <div class="progress-bar__bar progress-bar__bar--short"></div>
    </div>
    <div class="albums__pic">
      <img
        :src="album.cover.path"
        alt="Калининград времен 1920-1930 годов"
        v-if="isCoverLoaded"
      />
      <div class="albums__icon" v-else>
        <SvgIcon :name="'noimage'" />
      </div>
    </div>
    <div class="albums__info">
      <div class="albums__title">
        {{ album.name ? album.name : "-" }}
      </div>
      <div class="albums__description" v-if="album.count">
        {{ album.count }} фото
      </div>
    </div>
  </router-link>
</template>
<script>
import SvgIcon from "@/components/SvgIcon.vue";
const name = "AlbumCard";

const props = {
  album: {
    type: Object,
    default: () => {
      return {};
    },
  },
};

function data() {
  return {
    isCoverLoaded: false,
  };
}

function mounted() {
  this.preloadImage();
}

function preloadImage() {
  const inst = this;

  if (inst.album.hasOwnProperty("cover")) {
    let img = new Image();
    img.onload = function () {
      inst.isCoverLoaded = true;
    };
    img.src = inst.album.cover.path;
  }
}

export default {
  name,
  props,
  mounted,
  data,
  methods: {
    preloadImage,
  },
  components: {
    SvgIcon,
  },
};
</script>
