import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import dualphoto from "./modules/dualphoto.js";
import albums from "./modules/albums.js";
import upload from "./modules/upload.js";
import common from "./modules/common.js";
import reader from "./modules/reader.js";
import books from "./modules/books.js";
import cycles from "./modules/cycles.js";

export default new Vuex.Store({
  modules: {
    dualphoto,
    albums,
    upload,
    common,
    reader,
    books,
    cycles,
  },
  strict: true,
});
