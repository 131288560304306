<template>
  <footer class="footer">
    <div class="footer__container container">
      <div class="footer__main">
        <router-link class="footer__logo" to="/">
          <img src="../assets/logo/logo.svg" alt="История Калининграда" />
        </router-link>
        <ul class="footer__nav">
          <li>
            <router-link to="/">О проекте</router-link>
          </li>
          <li>
            <router-link to="/books">Книги</router-link>
          </li>
          <li>
            <router-link to="/albums">Фотографии</router-link>
          </li>
          <li>
            <router-link to="/videos">Видео</router-link>
          </li>
          <li>
            <router-link to="/map">Карта</router-link>
          </li>
        </ul>
      </div>
      <div class="footer__add">
        <ul class="footer__social">
          <li>
            <a
              class="social"
              href="https://vk.com/a.kropotkin69"
              target="_blank"
            >
              <SvgIcon name="vk" />
            </a>
          </li>
          <li>
            <a
              class="social"
              href="https://www.facebook.com/akropotkin"
              target="_blank"
            >
              <SvgIcon name="fb" />
            </a>
          </li>
          <li>
            <a
              class="social"
              href="https://www.youtube.com/channel/UCWaB9n5ZnqrPraY4YDVcuGA/videos"
              target="_blank"
            >
              <SvgIcon name="yt" />
            </a>
          </li>
          <li>
            <a
              class="social"
              href="https://www.instagram.com/akropotkin/"
              target="_blank"
            >
              <SvgIcon name="insta" />
            </a>
          </li>
        </ul>
        <div class="footer__add-links">
          <router-link to="/privacy-policy"
            >Политика конфиденциальности</router-link
          >
          <a href="https://giveandgo.ru" target="_blank">Сделано в Give&GO</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "app-footer",
  components: {
    SvgIcon,
  },
};
</script>
