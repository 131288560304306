<template>
  <div class="regular-page regular-page--dark">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>Видео</li>
        </ul>
        <div class="regular-page__description">
          <h1 class="regular-page__description-title">Видео</h1>
          <div class="regular-page__description-text">
            Сборник видео прогулок по Калининграду, рассказывающих о становлении
            и развитии города с момента штурма Кёнигсберга в 1945 году и до
            наших дней. Каждый ролик наглядно показывает, что история всегда
            рядом с нами. Вместе с ведущим вы пройдете по уникальным местам
            Калининграда, больше узнаете об объектах его культурного наследия,
            познакомитесь с биографиями выдающихся жителей.<br />
            Цикл выходил в формате телевизионных передач на региональном
            телевидении, а сейчас вы можете посмотреть любой из выпусков на
            нашем портале.<br />
            Еще один цикл видео роликов посвящен легендарным личностям,
            оставившим след в истории Янтарного края. Некоторые из них хорошо
            знакомы зрителям, истории других станут для вас открытием.<br />
          </div>
        </div>
      </div>
    </div>
    <Cycles />
  </div>
</template>
<script>
import Cycles from "@/components/Cycles";

export default {
  name: "videos",
  components: {
    Cycles,
  },
};
</script>
