import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";

// initial state
const state = {
  books: [],
  kaliningrad: {},
  legends: {},
  walks: {},
  assault: {},
  region: {},
  walksSecond: {},
  walksThird: {},
  kenig: {},
};

// getters
const getters = {};

// mutations
const mutations = {
  setBooks(state, value) {
    state.books = value;

    state.kaliningrad = value[0];
    state.legends = value[1];
    state.walks = value[2];
    state.assault = value[3];
    state.region = value[4];
    state.walksSecond = value[5];
    state.walksThird = value[6];
    state.kenig = value[7];
  },
};

const actions = {
  getBooks({ commit }) {
    return new Promise((resolve, reject) => {
      new AjaxProvider("books", "get", {
        with_reviews: true,
      }).request((xhr) => {
        const response = getJSON(xhr.responseText);
        if (xhr.status == 200) {
          commit("setBooks", response.data);
          if (typeof resolve === "function") {
            resolve();
          }
        } else {
          if (typeof reject === "function") {
            reject(response);
          }
        }
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
