<template>
  <div class="books-page__form">
    <form class="form" @submit.prevent="validate">
      <div class="container">
        <div class="form__title">
          Новая книга автора уже в работе. Угадай тему будущей книги и получи в
          подарок коллекционное издание с автографом автора.
        </div>
        <div class="form__preview"></div>
        <div class="form__wrapper">
          <div class="field">
            <input
              placeholder="Ваше имя"
              type="text"
              class="field__input"
              v-model="formData.name"
              :class="{ error: errors.name }"
            />
            <div class="field__error">{{ errors.name }}</div>
          </div>
          <div class="field">
            <input
              placeholder="E-mail для обратной связи"
              type="text"
              class="field__input"
              v-model="formData.email"
              :class="{ error: errors.email }"
            />
            <div class="field__error">{{ errors.email }}</div>
          </div>
          <div class="field">
            <textarea
              class="field__input field__input--textarea"
              v-model="formData.text"
              placeholder="Ваш вариант названия книги или тема"
              :class="{ error: errors.text }"
            ></textarea>
            <div class="field__error">{{ errors.text }}</div>
          </div>
          <div class="field">
            <label class="checkbox"
              ><input type="checkbox" v-model="formData.confirm" />Я принимаю
              условия
              <a href="/privacy-policy" target="_blank"
                >Политики конфиденциальности</a
              ></label
            >
            <div class="field__error">{{ errors.confirm }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button class="button" type="submit">Отправить</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import swal from "sweetalert";
import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";
import Validator from "@/utils/Validator.js";

const name = "GuessForm";

function data() {
  return {
    formData: {
      name: "",
      text: "",
      email: "",
      confirm: false,
    },
    validationRules: {
      name: "required",
      text: "required",
      email: "email",
      confirm: "required",
    },
    errors: {
      name: "",
      text: "",
      email: "",
      confirm: "",
    },
    validationRequest: false,
    formDataWatcher: false,
  };
}

const props = {
  bookId: {
    type: [Number, String],
    default: "",
  },
};

const computed = {
  loading() {
    return this.$store.state.common.loading;
  },
};

const watch = {
  validationRequest(value) {
    let inst = this;
    if (value) {
      inst.formDataWatcher = inst.$watch(
        "formData",
        () => {
          inst.validate();
        },
        {
          deep: true,
        }
      );
    } else {
      if (typeof inst.formDataWatcher == "function") {
        inst.formDataWatcher();
        inst.formDataWatcher = false;
      }
    }
  },
};

function validate(enableSubmit = false) {
  const inst = this;
  const validation = new Validator(inst.formData, inst.validationRules).make();
  inst.errors = validation.errors;

  if (!validation.isValid) {
    inst.validationRequest = true;
    return;
  }

  enableSubmit && inst.submit();
}

function submit() {
  const inst = this;

  if (inst.loading) {
    return;
  }

  inst.$store.commit("common/setRequest", true);

  new AjaxProvider(`service/guess`, "post", inst.formData).request(
    (xhr) => {
      const response = getJSON(xhr.responseText);
      if (xhr.status == 200) {
        swal("Спасибо!", "Ответ успешно сохранен", "success");
        inst.validationRequest = false;
        Object.assign(inst.formData, {
          name: "",
          email: "",
          text: "",
          confirm: false,
        });
      } else {
        inst.handleAPIError(response);
      }
      inst.$store.commit("common/setRequest", false);
    },
    (response) => {
      inst.$store.commit("common/setRequest", false);

      inst.handleAPIError(response);
    }
  );
}

export default {
  name,
  data,
  props,
  computed,
  watch,
  methods: {
    validate,
    submit,
  },
};
</script>
