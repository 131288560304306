<template>
  <transition name="modal">
    <div class="modal-mask" @click="$emit('close')">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body" @click.stop>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
const name = "Modal";

function created() {
  document.addEventListener("keyup", this.onKeyup);
  document.body.classList.add("body--modal");
  var scrollBarWidth = this.measureScrollBar();
  document.body.setAttribute(
    "style",
    "padding-right: " + scrollBarWidth + "px;"
  );
}

function onKeyup(event) {
  const inst = this;
  if (event.which == 27) {
    inst.$emit("close");
  }
}

function measureScrollBar() {
  var scrollDiv = document.createElement("div");
  scrollDiv.className = "modal-scrollbar-measure";
  scrollDiv.setAttribute("id", "measure");
  document.body.appendChild(scrollDiv);
  var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
}

function beforeDestroy() {
  document.removeEventListener("keyup", this.onKeyup);
  document.body.removeAttribute("style");
  document.body.classList.remove("body--modal");
}

export default {
  name,
  beforeDestroy,
  created,
  methods: {
    onKeyup,
    measureScrollBar,
  },
};
</script>
<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  max-width: 700px;
  width: auto;
  margin: 0px auto;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
