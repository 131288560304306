<template>
  <header
    class="header"
    :class="{
      'header--scroll': scrolled,
      'header--transparent': header,
      'header--no-fix': noFix,
      'header--open': menu,
    }"
  >
    <div class="header__container container">
      <router-link class="header__logo" to="/">
        <img src="../assets/logo/logo.svg" alt="История Калининграда" />
      </router-link>
      <ul class="header__nav">
        <li>
          <router-link to="/">О проекте</router-link>
        </li>
        <li>
          <router-link to="/books">Книги</router-link>
        </li>
        <li>
          <router-link to="/albums">Фотографии</router-link>
        </li>
        <li>
          <router-link to="/videos">Видео</router-link>
        </li>
        <li>
          <router-link to="/map">Карта</router-link>
        </li>
      </ul>
    </div>
    <div class="header__hub" @click="menuToggle">
      <span class="header__hub-helper"></span>
    </div>
  </header>
</template>

<script>
export default {
  name: "app-header",
  data: function () {
    return {
      scrolled: false,
      header: false,
      noFix: false,
      menu: false,
    };
  },
  mounted: function () {
    this.scrollCheck();
    this.routerCheck();

    document.addEventListener("scroll", () => {
      this.scrollCheck();
    });
  },
  watch: {
    $route: function () {
      this.routerCheck();
      this.menu = false;
    },
    menu: function (val) {
      if (val) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    menuToggle() {
      this.menu = !this.menu;
    },
    scrollCheck() {
      if (window.pageYOffset > 20) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
    routerCheck() {
      if (this.$route.meta) {
        if (this.$route.meta.header) {
          this.header = true;
        } else {
          this.header = false;
        }

        if (this.$route.meta.noFix) {
          this.noFix = true;
        } else {
          this.noFix = false;
        }
      } else {
        this.header = false;
        this.noFix = false;
      }
    },
  },
};
</script>
