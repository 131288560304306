<template>
  <div class="regular-page">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>
            <router-link to="/albums">Фотографии</router-link>
          </li>
          <li>Было - Стало</li>
        </ul>
        <h1 class="regular-page__title">Было - Стало</h1>
      </div>
    </div>
    <div class="container">
      <template v-if="fetched">
        <DualPhoto v-for="pic in dualPics" :pic="pic" :key="pic.id" />
      </template>
      <Preloader v-else />
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";

import DualPhoto from "@/components/DualPhoto.vue";
import Preloader from "@/components/Preloader.vue";

const computed = {
  dualPics() {
    return this.$store.state.dualphoto.items;
  },
};

function data() {
  return {
    fetched: false,
  };
}

function created() {
  const inst = this;
  if (!inst.dualPics.length) {
    inst.$store.dispatch("dualphoto/getPics").then(
      () => {
        inst.fetched = true;
      },
      (response) => {
        inst.handleAPIError(response);
      }
    );
  } else {
    inst.fetched = true;
  }
}

function handleAPIError(response) {
  const message = response.error || "Что-то пошло не так, попробуйте позже";
  swal("Ошибка", message, "error");
}

export default {
  name: "historyGallery",
  created,
  data,
  computed,
  methods: {
    handleAPIError,
  },
  components: {
    DualPhoto,
    Preloader,
  },
};
</script>
