<template>
  <div class="regular-page">
    <div class="regular-page__header">
      <div class="container">
        <ul class="breadcrumbs">
          <li>
            <router-link to="/">Главная</router-link>
          </li>
          <li>Фотографии</li>
        </ul>
        <h1 class="regular-page__title" v-if="title">
          {{ title }}
        </h1>
      </div>
    </div>
    <div class="container">
      <template v-if="photos.length">
        <VueGallery :images="photos" :index="index" @close="index = null" />
        <div
          class="gallery"
          v-masonry
          transition-duration="0s"
          item-selector=".item"
        >
          <div class="row">
            <div
              v-masonry-tile
              class="col-m-4 col-s-6 item"
              v-for="(photo, key) in photos"
              :key="key"
            >
              <img
                :src="photo"
                :alt="photo.title"
                @click.prevent="index = key"
              />
            </div>
          </div>
        </div>
      </template>
      <Preloader v-else />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import AjaxProvider from "@/utils/AjaxProvider.js";
import getJSON from "@/utils/getJSON.js";
import Preloader from "@/components/Preloader.vue";

import VueGallery from "vue-gallery";
import { VueMasonryPlugin } from "vue-masonry";
Vue.use(VueMasonryPlugin);

function created() {
  this.fetchData();
}

function data() {
  return {
    title: "",
    queue: [],
    photos: [],
    index: null,
  };
}

function fetchData() {
  const inst = this;

  const id = inst.$route.params.id;

  new AjaxProvider(`albums/${id}`, "get", {
    with_photos: true,
  }).request((xhr) => {
    if (xhr.status == 200) {
      let response = getJSON(xhr.responseText);
      inst.title = response.name;
      inst.queue = response.photos;
      inst.queueStep();
    }
  });
}

function queueStep() {
  const inst = this;

  if (inst.queue.length) {
    let image = new Image();
    let path = inst.queue[0].path;
    image.onload = function () {
      inst.photos.push(path);
      inst.queue.splice(0, 1);
      inst.queueStep();
    };
    image.src = path;
  }
}

export default {
  name: "gallery",
  created,
  data,
  methods: {
    fetchData,
    queueStep,
  },
  components: {
    Preloader,
    VueGallery,
  },
};
</script>
<style lang="scss">
.gallery {
  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
</style>
