<template>
  <div class="video mb-30" @click.prevent="$emit('click')">
    <div class="video__preview">
      <img
        :src="`https://i.ytimg.com/vi/${video.link}/sddefault.jpg`"
        v-if="imgLoaded"
      />
      <div class="albums__icon" v-else>
        <SvgIcon :name="'noimage'" />
      </div>
    </div>
    <div class="video__info">
      <p class="video__title" v-if="video.title">{{ video.title }}</p>
      <div class="video__add-info">
        <div class="video__description">
          <p>Андрей Кропоткин</p>
          <p v-if="views">Просмотров: {{ views }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@/components/SvgIcon";
const name = "videoItem";

const props = {
  video: {
    type: Object,
    default: () => {
      return {};
    },
  },
};

function data() {
  return {
    fetched: false,
    videoProps: {},
    views: false,
    imgLoaded: false,
  };
}

function created() {
  this.fetchData();
}

function fetchData() {
  const inst = this;

  if (inst.video.link) {
    let img = new Image();
    img.onload = function () {
      inst.imgLoaded = true;
    };
    img.src = `https://i.ytimg.com/vi/${inst.video.link}/sddefault.jpg`;

    let xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${inst.video.link}&key=AIzaSyAXiS5J6dou1UyNPFM7zj1WF_N2xM9Ltvc`
    );
    xhr.send();

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let response = JSON.parse(xhr.responseText);
        try {
          inst.views = response.items[0].statistics.viewCount;
        } catch (e) {
          console.log(e);
        }
      }
    };
  }
}

export default {
  name,
  props,
  data,
  created,
  methods: {
    fetchData,
  },
  components: {
    SvgIcon,
  },
};
</script>
